import React, { useEffect, useState } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import useConfig from "../../hooks/useConfig";
import AuthLayout from "../layouts/AuthLayout";
import Layout from "../layouts/Layout";
import * as Storage from "../../store/LocalStorage";
import { LazyLoader } from "../../assets/loaders";
import Login from "../containers/login";
import DepartmentMaster from "../containers/masters/departmentMaster";
import DepartmentDetails from "../containers/masters/departmentMaster/DepartmentDetails";
import DesignationMaster from "../containers/masters/designationMaster";
import DesignationDetails from "../containers/masters/designationMaster/DesignationDetails";
import PlantMaster from "../containers/plants";
import PlantDetails from "../containers/plants/PlantDetails";
import AccessRightMaster from "../containers/masters/accessRight";
import EmployeeMaster from "../containers/masters/employeeMaster";
import FunctionalMaster from "../containers/masters/functionalMaster";
import PermissionRoute from "./PermissionRoute";
import Audittrail from "../containers/audittrail";
import axios from "axios";
import MainServices from "../../services/MainServices";
import usePermission from "../../hooks/usePermission";
import PageNotFound from "../containers/pageNotFound";
import DocumentDashboard from "../containers/documentDashboard";
import AccessRole from "../containers/accessRole/AccessRole";
import SecurityGroup from "../containers/securityGroup/securityGroup";
import SecurityPermission from "../containers/securityPermission/securityPermission";
import Profile from "../containers/profile";
import Tools from "../containers/tools";
import EmpProfile from "../containers/masters/employeeMaster/EmpProfile";
import BranchMasters from "../containers/masters/branch/BranchMaster";
import BranchDetails from "../containers/masters/branch/BranchDetails";
import { notifySuccess, notifyWarn } from "../../assets/elements/Toast";
import ForgetPassword from "../containers/forgetPassword";
import ResetPassword from "../containers/resetPassword/ResetPassword";
import FeatureSetting from "../containers/featureSetting";
import Division from "../containers/division";
import DivisionDetails from "../containers/division/DivisionDetails";
import PasswordPolicies from "../containers/PassPolicies";
import PasswordPoliciesDetails from "../containers/PassPolicies/Addpasspolicies";
import IDCMatrix from "../containers/idcMatrix";
import AddIDCMatrix from "../containers/idcMatrix/AddIDCMatrix";
import IDCMatrixDetails from "../containers/idcMatrix/IDCMatrixDetails";
import ApprovalDashboard from "../containers/approvalDashboard";
import MyTasks from "../containers/myTask";
import WorkflowGroup from "../containers/masters/workflowGroup";
import AddWorkflowGroup from "../containers/masters/workflowGroup/AddWorkflowGroup";
import WorkflowGroupDetails from "../containers/masters/workflowGroup/WorkflowGroupDetails";

export default function CustomRoutes() {
  const { user, updateUserContext } = useAuth();
  const { updatePermissions } = usePermission();
  const [loading, setLoading] = useState(false);
  const config = useConfig();
  const navigate = useNavigate();
  const { getRolePermissionsByRoles, getUserDetails } = MainServices();
  const location = useLocation();

  const token = new URLSearchParams(location.search).get("token");

  useEffect(() => {
    if (token) {
      getUser(token);
    } else {
      checkSession();
    }
    // eslint-disable-next-line
  }, []);

  const getUser = async () => {
    setLoading(true);
    const details = {};
    await axios
      .all([getUserDetails(details, token)])
      .then(function (res) {
        if (res[0]) {
          let resData = res[0];
          let message = resData.message;
          if (resData.success) {
            let userDetails = resData?.data || {};
            userDetails = {
              token,
              email: userDetails?.empEmail,
              name: userDetails?.empName,
              role: userDetails?.role,
              user_id: userDetails?.publicId,
              empFirstLoginStatus: userDetails?.empFirstLoginStatus,
            };
            Storage.set(config.sessionKey, JSON.stringify(userDetails));
            updateUserContext(userDetails);
            getRolePermissions(token);
            navigate("/dashboard");
            notifySuccess("User Login Successfully");
            setLoading(true);
          } else {
            notifyWarn(message);
            setLoading(true);
          }
        } else {
          setLoading(true);
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message) console.log("An error occurred in User Signin");
      });
  };

  const checkSession = () => {
    setLoading(true);
    let session = Storage.get(config.sessionKey);
    if (session) {
      session = JSON.parse(session);
      getRolePermissions(session.token);
      updateUserContext(session);
    } else {
      navigate({ pathname: "/login", search: location.search });
      setLoading(false);
    }
  };

  //api
  const getRolePermissions = async (token) => {
    const details = {
      source: "auth",
    };
    await axios
      .all([getRolePermissionsByRoles(details, token)])
      .then(async function (res) {
        if (res[0]) {
          let resData = res[0];
          if (resData.success) {
            let rolePermissions = resData.data ? resData.data : [];
            rolePermissions = rolePermissions.map(
              (each) => each.permissionname
            );
            await updatePermissions(rolePermissions);
            setLoading(false);
          } else {
          }
        } else {
        }
      })
      .catch(function (res) {
        console.log(res);
        if (res.message)
          console.log("An error occurred in getting Permissions");
      });
  };

  return loading ? (
    <LazyLoader />
  ) : user?.token ? (
    user?.empFirstLoginStatus === "false" ? (
      <AuthLayout>
        <Routes>
          <Route
            path="/dashboard"
            element={
              <PermissionRoute
                component={DocumentDashboard}
                required_permission="Dashboard_read"
              />
            }
          />
          <Route
            path="/audittrial"
            element={
              <PermissionRoute
                component={Audittrail}
                required_permission="Audit_read"
              />
            }
          />
          <Route
            path="/password-policies"
            element={
              <PermissionRoute
                component={PasswordPolicies}
                required_permission="Audit_read"
              />
            }
          />
          <Route
            path="/password-policies/add-policies"
            element={
              <PermissionRoute
                component={PasswordPoliciesDetails}
                required_permission="Audit_read"
              />
            }
          />

          <Route
            path="/tasks"
            element={
              <PermissionRoute
                component={ApprovalDashboard}
                required_permission="EventWorkflow_read"
              />
            }
          />

          <Route
            path="/my-tasks"
            element={
              <PermissionRoute
                component={MyTasks}
                required_permission="EventWorkflow_read"
              />
            }
          />

          <Route
            path="/departments"
            element={
              <PermissionRoute
                component={DepartmentMaster}
                required_permission="Department_read"
              />
            }
          />
          <Route
            path="/workflow-group"
            element={
              <PermissionRoute
                component={WorkflowGroup}
                required_permission="WorkflowGroup_read"
              />
            }
          />
          <Route
            path="/workflow-group/add-workflow-group"
            element={
              <PermissionRoute
                component={AddWorkflowGroup}
                required_permission="WorkflowGroup_read"
              />
            }
          />
          <Route
            path="/workflow-group/details"
            element={
              <PermissionRoute
                component={WorkflowGroupDetails}
                required_permission="WorkflowGroup_read"
              />
            }
          />
          <Route
            path="/departments/details"
            element={
              <PermissionRoute
                component={DepartmentDetails}
                required_permission="Department_read"
              />
            }
          />

          <Route
            path="/designations"
            element={
              <PermissionRoute
                component={DesignationMaster}
                required_permission="Designation_read"
              />
            }
          />

          <Route
            path="/designations/details"
            element={
              <PermissionRoute
                component={DesignationDetails}
                required_permission="Designation_read"
              />
            }
          />

          <Route
            path="/division"
            element={
              <PermissionRoute
                component={Division}
                required_permission="Division_read"
              />
            }
          />

          <Route
            path="/division/details"
            element={
              <PermissionRoute
                component={DivisionDetails}
                required_permission="Division_read"
              />
            }
          />

          <Route
            path="/plants"
            element={
              <PermissionRoute
                component={PlantMaster}
                required_permission="Plant_read"
              />
            }
          />
          <Route
            path="/plants/details"
            element={
              <PermissionRoute
                component={PlantDetails}
                required_permission="Plant_read"
              />
            }
          />
          <Route
            path="/branch"
            element={
              <PermissionRoute
                component={BranchMasters}
                required_permission="Branch_read"
              />
            }
          />
          <Route
            path="/branch/details"
            element={
              <PermissionRoute
                component={BranchDetails}
                required_permission="Branch_read"
              />
            }
          />

          <Route
            path="/access-right"
            element={
              <PermissionRoute
                component={AccessRightMaster}
                required_permission="AccessRight_read"
              />
            }
          />

          <Route
            path="/workflow"
            element={
              <PermissionRoute
                component={IDCMatrix}
                required_permission="Workflow_read"
              />
            }
          />

          <Route
            path="/workflow/add-workflow"
            element={
              <PermissionRoute
                component={AddIDCMatrix}
                required_permission="Workflow_write"
              />
            }
          />

          <Route
            path="/workflow/workflow-details"
            element={
              <PermissionRoute
                component={IDCMatrixDetails}
                required_permission="Workflow_read"
              />
            }
          />

          <Route
            path="/user-management"
            element={
              <PermissionRoute
                component={EmployeeMaster}
                required_permission="Employee_read"
              />
            }
          />

          <Route
            path="/masters/functional"
            element={
              <PermissionRoute
                component={FunctionalMaster}
                required_permission=""
              />
            }
          />

          <Route
            path="/access-role"
            element={
              <PermissionRoute
                component={AccessRole}
                required_permission="AccessRight_read"
              />
            }
          />

          <Route
            path="/security-group"
            element={
              <PermissionRoute
                component={SecurityGroup}
                required_permission="SecurityGroup_read"
              />
            }
          />

          <Route
            path="/security-group-permission"
            element={
              <PermissionRoute
                component={SecurityPermission}
                required_permission="SecurityGroupPermission_read"
              />
            }
          />

          <Route
            path="/profile"
            element={
              <PermissionRoute
                component={Profile}
                required_permission="UserProfile_read"
              />
            }
          />
          <Route
            path="/emp-profile"
            element={
              <PermissionRoute
                component={EmpProfile}
                required_permission="UserProfile_read"
              />
            }
          />

          <Route
            path="/tools"
            element={
              <PermissionRoute
                component={Tools}
                required_permission="Tools_read"
              />
            }
          />
          <Route
            path="/feature-settings"
            element={
              <PermissionRoute
                component={FeatureSetting}
                required_permission="Setting_read"
              />
            }
          />
          <Route path="/page-not-found" element={<PageNotFound />} />
          <Route path="/reset-password" element={<ResetPassword />} />

          <Route
            path="*"
            element={
              <PermissionRoute
                component={DocumentDashboard}
                required_permission="Dashboard_read"
              />
            }
          />
        </Routes>
      </AuthLayout>
    ) : (
      <Routes>
        <Route path="*" element={<ResetPassword />} />
      </Routes>
    )
  ) : (
    <Layout>
      <Routes>
        {/* <Route path="/reset-password"element={<ResetPassword/>}/> */}
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<Login />} />
        <Route path="/page-not-found" element={<PageNotFound />} />
        <Route path="/forget-password" element={<ForgetPassword />} />
      </Routes>
    </Layout>
  );
}
